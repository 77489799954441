
import { defineComponent, ref, reactive, toRefs, inject, nextTick, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { roleApi } from '@/api/modules/role'

export default defineComponent({
  name: 'roleAddOrEditDialog',
  setup (props, ctx) {
    // 获取refs
    const addOrEditFormRef = ref()
    const treeRef = ref()

    // 接收父级的方法
    const updateList: any = inject('updateList')

    // 新增、编辑表单数据
    const addOrEditFormInit = () => {
      return {
        name: '',
        power_ids: []
      }
    }

    const data: any = reactive({
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      form: addOrEditFormInit(),
      rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
      },
      filterText: '',
      tableData: [], // 树形数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      determineBtnLoading: false,

      // 对话框显示
      async showAddOrEdit (id: number) {
        data.addOrEditType = id ? 1 : 0

        await data.getPowerList()
        data.addOrEditDialog = true

        // 有id时赋值
        if (id) {
          const { res } = await roleApi.getInfo({ id })
          console.log('根据id获取详情', res)
          data.form = res

          // 赋值已选中的树形数据
          nextTick(() => {
            const checkedKeysList = data.collectId(res.power)
            // console.log('checkedKeysList', checkedKeysList)

            const arr = []
            for (const i of checkedKeysList) {
              const TreeNode = treeRef.value.getNode(i)
              // console.log('TreeNode', TreeNode)
              if (!TreeNode || !TreeNode.childNodes || !TreeNode.childNodes.length) {
                arr.push(i)
              }
            }
            // console.log('arr', arr)
            treeRef.value.setCheckedKeys(arr)
          })
        }
      },
      // 对话框关闭
      addOrEditCancel () {
        // console.log('对话框关闭')
        addOrEditFormRef.value.resetFields()
        data.form = Object.assign({}, addOrEditFormInit())
        data.filterText = ''
        data.tableData = []
        treeRef.value.setCheckedKeys([])
      },
      // 对话框确认
      addOrEditDetermine () {
        console.log('form', data.form)

        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          // 处理选中的权限
          const permissionList = [...treeRef.value.getCheckedKeys(), ...treeRef.value.getHalfCheckedKeys()]
          console.log('permissionList', permissionList)
          if (!permissionList.length) {
            return ElMessage.error('请勾选权限')
          }
          data.form.power_ids = JSON.stringify(permissionList)

          data.determineBtnLoading = true
          await roleApi.save(data.form)
          ElMessage.success(`${data.addOrEditTypeTitle[data.addOrEditType]}成功`)
          data.addOrEditDialog = false
          data.determineBtnLoading = false

          // 父级更新列表
          updateList('place')
        })
      },

      // 获取所有权限
      async getPowerList () {
        const { res } = await roleApi.getPowerList({})
        console.log('获取所有权限', res)
        data.tableData = res
      },
      // 递归提取拥有的id
      collectId (arr: any, ids: any = []) {
        arr.forEach((item: any) => {
          if (item.id && item.checked === 1) {
            ids.push(item.id)
          }
          if (item.children) {
            data.collectId(item.children, ids)
          }
        })
        return ids
      },
      // 树形过滤
      filterNode (value: any, data: any) {
        if (!value) return true
        return data.name.indexOf(value) !== -1
      }
    })

    watch(
      () => data.filterText,
      (val) => {
        treeRef.value.filter(val)
      }
    )

    return {
      ...toRefs(data),
      addOrEditFormRef,
      treeRef
    }
  }
})
